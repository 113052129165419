import { formatHumanName } from '@medplum/core';
import { Encounter, Patient, Reference } from '@medplum/fhirtypes';
import { useMedplumProfile } from '@medplum/react';
import React, { createContext, useState, useEffect } from 'react';
import useRoom, { Result as RoomContext } from './room/useRoom';
import {
  ConnectOptions,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
} from 'twilio-video';
import { useAlwaysActive } from '@/hooks/useActivityTracking';
import { VideoParticipantIdentity } from 'imagine-dsl';
import { getConfig } from '@/config';
import { logError } from '@/errors';
import { useApiClient } from '@/hooks/useApiClient';
import { useDyteClient } from '@dytesdk/react-web-core';
import DyteClient from '@dytesdk/web-core';

export interface IVideoContext extends RoomContext {
  isStartingCall: boolean;
  isEndingCall: boolean;
  startCall: (patient: Reference<Patient>) => Promise<Encounter>;
  startTestCall: (patient: Reference<Patient>) => Promise<Encounter>;
  currentEncounter: Encounter | null;
  endCall: () => void;
  activeCall: boolean;
  activeTwilioCall: boolean;
  activeDyteCall: boolean;
  callStartTime?: Date;
  callEndTime?: Date;
  mainParticipant?: Participant;
  setMainParticipant: (participant: Participant | undefined) => void;
  dyteMeeting: DyteClient | undefined;
  errorModalOpen: boolean;
  setErrorModalOpen: (open: boolean) => void;
}

export const VideoContext = createContext<IVideoContext>({} as IVideoContext);

interface VideoProviderProps {
  children: React.ReactNode;
}

export const useVideo = (): IVideoContext => {
  return React.useContext(VideoContext);
};

const VideoProvider = ({ children }: VideoProviderProps): JSX.Element => {
  const profile = useMedplumProfile();
  const config = getConfig();

  //TODO: separate dyte & twilio into their own providers
  const [activeCall, setActiveCall] = useState(false);
  const [activeTwilioCall, setActiveTwilioCall] = useState(false);
  const [activeDyteCall, setActiveDyteCall] = useState(false);
  const [callStartTime, setCallStartTime] = useState<Date | undefined>();
  const [localAudioTrack, setLocalAudioTrack] = useState<LocalAudioTrack>();
  const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack>();
  const [isStartingCall, setIsStartingCall] = useState(false);
  const [isEndingCall, setIsEndingCall] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const [currentEncounter, setCurrentEncounter] = useState<Encounter | null>(null);

  const room = useRoom();

  const { localParticipant, remoteParticipants, dominantSpeaker } = room;
  const defaultMainParticipant = remoteParticipants.length > 0 ? remoteParticipants[0] : localParticipant;

  const [mainParticipant, setMainParticipant] = useState<Participant | undefined>(defaultMainParticipant);
  const [dyteMeeting, initMeeting] = useDyteClient();

  useAlwaysActive({
    userId: profile?.id,
    when: !!currentEncounter,
  });

  useEffect(() => {
    const newMainParticipant = remoteParticipants.length > 0 ? remoteParticipants[0] : localParticipant;

    setMainParticipant(dominantSpeaker || newMainParticipant);
  }, [remoteParticipants, localParticipant, dominantSpeaker]);

  useEffect(() => {
    setActiveCall(activeTwilioCall || activeDyteCall);
  }, [activeTwilioCall, activeDyteCall]);

  const apiClient = useApiClient();
  const startCall = async (patient: Reference<Patient>, isTest: boolean = false): Promise<Encounter> => {
    if (currentEncounter) {
      return currentEncounter;
    }
    setIsStartingCall(true);

    const name = (profile?.name?.[0] && formatHumanName(profile!.name[0])) || 'Anonymous';
    const identity: VideoParticipantIdentity | undefined = profile?.id
      ? {
          profileId: profile.id,
          profileType: 'Practitioner',
          name,
        }
      : undefined;
    // Create a LocalAudioTrack with Krisp noise cancellation enabled.
    const localAudioTrack = await createLocalAudioTrack({
      noiseCancellationOptions: {
        sdkAssetsPath: `../../${config.appEnv === 'local' ? 'public/' : ''}krisp-audio-plugin_v1.0.0`,
        vendor: 'krisp',
      },
    });
    setLocalAudioTrack(localAudioTrack);

    const localVideoTrack = await createLocalVideoTrack({
      noiseSuppression: true,
      frameRate: 24,
    });
    setLocalVideoTrack(localVideoTrack);

    return apiClient
      .fetch('/api/twilio/join', {
        method: 'POST',
        body: JSON.stringify({
          patient,
          identity,
          isTest,
        }),
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to join call');
        }
        return response.json();
      })
      .then(async (data): Promise<Encounter> => {
        const shouldUseDyte = data?.encounter?.meta?.tag?.some(
          (tag: { code: string }) => tag.code.toLowerCase() === 'dyte',
        );

        if (shouldUseDyte) {
          await initMeeting({
            // TODO: uncomment below to use actual token
            // authToken: data.token,
            authToken: 'testing placeholder',
          });
          setActiveDyteCall(true);
          setCurrentEncounter(data.encounter);
          return data.encounter;
        } else {
          const options: ConnectOptions = { name: patient.reference };
          // If the Krisp audio plugin fails to load, then a warning message will be logged
          // in the browser console, and the "noiseCancellation" property will be set to null.
          // You can still use the LocalAudioTrack to join a Room. However, it will use the
          // browser's noise suppression instead of the Krisp noise cancellation. Make sure
          // the "sdkAssetsPath" provided in "noiseCancellationOptions" points to the correct
          // hosted path of the plugin assets.
          if (localAudioTrack.noiseCancellation) {
            //if noiseCancellation is available set the tracks connection Property and enable
            await localAudioTrack.noiseCancellation.enable();
            options.tracks = [localAudioTrack, localVideoTrack];
          }

          room.connectRoom({
            token: data.token,
            options,
          });

          setActiveTwilioCall(true);
          setCallStartTime(new Date());
          setCurrentEncounter(data.encounter);
          return data.encounter;
        }
      })
      .catch((error) => {
        setErrorModalOpen(true);
        setCurrentEncounter(null);
        logError(error);
        localAudioTrack?.stop();
        localVideoTrack?.stop();
        throw error;
      })
      .finally(() => {
        setIsStartingCall(false);
      });
  };

  const endCall = async (): Promise<void> => {
    if (currentEncounter?.id) {
      setIsEndingCall(true);
      const name = (profile?.name?.[0] && formatHumanName(profile!.name[0])) || 'Anonymous';
      const identity: VideoParticipantIdentity | undefined = profile?.id
        ? {
            profileId: profile.id,
            profileType: 'Practitioner',
            name,
          }
        : undefined;

      apiClient
        .fetch('/api/twilio/leave', {
          method: 'POST',
          body: JSON.stringify({
            patientId: currentEncounter.subject?.reference?.split('/')[1],
            identity,
          }),
        })
        .then(() => {
          setCallStartTime(undefined);
          setCurrentEncounter(null);

          //TODO: don't call twilio functions if dyte is used
          // twilio-specific
          setActiveTwilioCall(false);
          localAudioTrack?.stop();
          localVideoTrack?.stop();
          room.disconnectRoom();

          // dyte-specific
          if (activeDyteCall) {
            setActiveDyteCall(false);
          }
        })
        .catch(logError)
        .finally(() => {
          setIsEndingCall(false);
        });
    } else {
      if (activeDyteCall) {
        setActiveDyteCall(false);
      }
      //TODO: don't call twilio functions if dyte is used
      setActiveTwilioCall(false);
      setCallStartTime(undefined);
      setCurrentEncounter(null);
      room.disconnectRoom();
    }
  };

  const context: IVideoContext = {
    isStartingCall,
    isEndingCall,
    startCall,
    startTestCall: (patient: Reference<Patient>) => startCall(patient, true),
    currentEncounter,
    endCall,
    activeCall,
    activeTwilioCall,
    activeDyteCall,
    callStartTime,
    mainParticipant,
    setMainParticipant,
    dyteMeeting,
    errorModalOpen,
    setErrorModalOpen,
    ...room,
  };

  return <VideoContext.Provider value={context}>{children}</VideoContext.Provider>;
};

export default VideoProvider;
