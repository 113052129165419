import React, { useEffect } from 'react';
import { useVideo } from '../video/VideoProvider';
import { DyteMeeting, registerAddons } from '@dytesdk/react-ui-kit';
import { VideoInterfaceWrapper } from './VideoInterfaceWrapper';
import VideoBackground from '@dytesdk/ui-kit-addons/video-background';
import { CloseButton } from '@mantine/core';
import { useDyteSelector } from '@dytesdk/react-web-core';

export const DyteInterface = (): JSX.Element => {
  const { currentEncounter, dyteMeeting, endCall, activeDyteCall } = useVideo();
  const roomState = useDyteSelector((m) => m.self.roomState);

  useEffect(() => {
    // not ideal, but this moves Dyte's blur dialog above the patient drawer
    const dyteBg = document.querySelector('dyte-background-changer');
    if (dyteBg) {
      (dyteBg as HTMLElement).setAttribute('style', 'z-index: 200');
    }
  });

  useEffect(() => {
    if (!dyteMeeting || !currentEncounter) {
      return;
    }

    //adds our existing endCall function to the Dyte interface
    const roomLeftListener = () => {
      endCall();
    };
    dyteMeeting?.self.on('roomLeft', roomLeftListener);

    // eslint-disable-next-line consistent-return
    return () => {
      dyteMeeting.self.removeListener('roomLeft', roomLeftListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dyteMeeting]);

  const videoBackground = new VideoBackground({
    modes: ['blur'],
  });

  const dyteConfig = dyteMeeting ? registerAddons([videoBackground], dyteMeeting) : undefined;

  return (
    <VideoInterfaceWrapper activeCall={activeDyteCall}>
      {roomState === 'init' && (
        <CloseButton
          onClick={() => dyteMeeting?.leave()}
          c="white"
          top={10}
          right={10}
          pos="absolute"
          style={{
            zIndex: 1,
          }}
        />
      )}
      <DyteMeeting
        mode="fill"
        size="sm"
        style={{ height: '640px' }}
        meeting={dyteMeeting}
        showSetupScreen={true}
        config={dyteConfig}
      />
    </VideoInterfaceWrapper>
  );
};
