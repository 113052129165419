import { createReference, formatHumanName } from '@medplum/core';
import {
  BundleEntry,
  ClinicalImpression,
  ContactPoint,
  EpisodeOfCare,
  EpisodeOfCareStatusHistory,
  Extension as FHIRExtension,
  HumanName,
  Patient,
  Practitioner,
} from '@medplum/fhirtypes';

import {
  Attribution,
  CareTeamMemberRole,
  PreferredLanguage,
  ProgramStatus,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { HL7System, PatientType, System } from 'const-utils';
import {
  Maybe,
  Patient as GraphqlPatient,
  HumanName as GraphqlHumanName,
  CareTeamParticipant,
  CareTeam,
  RelatedPerson,
} from 'medplum-gql';
import { sub } from 'date-fns';
import { capitalize, compact, flatten, isEmpty, map, uniqBy } from 'lodash';
import { PatientContactRequest } from '../services/caregiverService';
import { addExtensions } from './extensions';
import { PatientCommunication, PatientWithAllPhones, PatientWithExtension } from './types/patient';
import { EpisodeOfCareWithStatus } from './types/episodeOfCare';

// TODO: Doc and test
export const isCaregiver = (patient: Patient | GraphqlPatient): boolean =>
  patient.meta?.tag?.find((tag) => tag.system === System.PatientType)?.code === PatientType.Caregiver;

export const phoneValue = (patient: PatientWithAllPhones & PatientWithExtension): string => {
  return getPhoneNumbers(patient)[0] || '';
};

/**
 * This function checks if a patient is 21 or older.
 * @param dob - the date of birth to check if they are 21 or older
 * @returns true if the patient is 21 or older
 */
export const is21OrOlder = (dob: Maybe<string> | undefined): boolean => {
  if (!dob) {
    return false;
  }

  const ptBirthDate = new Date(dob);

  return sub(new Date(), { years: 21 }) >= ptBirthDate;
};

/**
 * This function retrieves the phone numbers associated with a patient.
 * It first checks if there are any caregivers associated with the patient.
 * If there are, it selects the primary caregiver or the first caregiver in the list if no primary caregiver is found.
 * It then collects all the phone numbers (telecoms) associated with both the caregiver and the patient.
 * It filters out any telecoms that are not phone numbers or do not have a value.
 * It then checks if there is a primary phone number among the telecoms.
 * Finally, it returns a unique list of phone numbers, with the primary phone number (if any) listed first.
 *
 * @param patient - the patient to retrieve phone numbers for
 * @returns an array of phone numbers associated with the patient
 */

export const getPhoneNumbers = (patient: PatientWithAllPhones & PatientWithExtension): string[] => {
  return (getAllPhoneContactPoints(patient)
    ?.filter((telecom) => !!telecom.value)
    .map((telecom) => telecom.value) ?? []) as string[];
};

/**
 * This function retrieves the phone numbers associated with a patient.
 * It first checks if there are any caregivers associated with the patient.
 * If there are, it selects the primary caregiver or the first caregiver in the list if no primary caregiver is found.
 * It then collects all the phone numbers (telecoms) associated with both the caregiver and the patient.
 * It filters out any telecoms that are not phone numbers or do not have a value.
 * It then checks if there is a primary phone number among the telecoms.
 * Finally, it returns a unique list of phone numbers, with the primary phone number (if any) listed first.
 *
 * @param patient - the patient to retrieve phone numbers for
 * @returns an array of telecoms associated with the patient
 */
export const getAllPhoneContactPoints = (patient: PatientWithAllPhones & PatientWithExtension): ContactPoint[] => {
  const caregivers = (patient?.RelatedPersonList?.map((rp) => rp?.PatientList?.[0]).filter(Boolean) || []) as Patient[];
  const caregiver = caregivers.find((cg) => isPrimaryCaregiver(patient, cg)) || caregivers[0];

  const telecoms = [...(caregiver?.telecom || []), ...(patient?.telecom || [])]
    .filter((telecom) => telecom?.system === 'phone')
    .filter((telecom) => telecom?.value);

  const primaryTelecom = telecoms.find(
    (telecom) => telecom.extension?.find((e) => e.url === System.PrimaryPhone.toString() && e.valueBoolean === true),
  );

  return uniqBy(
    [...(primaryTelecom ? [primaryTelecom] : []), ...telecoms],
    (telecom) => telecom.value,
  ) as ContactPoint[];
};

/**
 *
 * @param patient - the patient with the needed type
 * @returns the patient type or empty string
 */
export const patientType = (patient: Patient): string => {
  const meta = patient.meta;

  if (meta) {
    const patientTypeTag = meta?.tag?.find((tag) => tag.system === 'https://imaginepediatrics.org/patient-type');
    return capitalize(patientTypeTag?.code || '');
  } else {
    return '';
  }
};

export const programStatusToEpisodeOfCareStatus: Record<ProgramStatus, EpisodeOfCare['status']> = {
  [ProgramStatus.NotEnrolled]: 'waitlist',
  [ProgramStatus.Enrolled]: 'planned',
  [ProgramStatus.Onboarded]: 'active',
  [ProgramStatus.Disenrolled]: 'finished',
};

export const episodeOfCareStatusToProgramStatus: Record<string, ProgramStatus> = {
  waitlist: ProgramStatus.NotEnrolled,
  planned: ProgramStatus.Enrolled,
  active: ProgramStatus.Onboarded,
  finished: ProgramStatus.Disenrolled,
  cancelled: ProgramStatus.Disenrolled,
};

// TODO: Doc and test
export const getProgramStatusFromEpisodeOfCareStatus = (status: string): string | null => {
  return episodeOfCareStatusToProgramStatus[status];
};

export const getEpisodeOfCareStatusFromProgramStatus = (status: ProgramStatus): EpisodeOfCare['status'] => {
  return programStatusToEpisodeOfCareStatus[status];
};

/**
 *
 * @param episodeOfCareList - episode of care list to find the status from
 * @returns the status of the episode of care
 */
export const episodeOfCareStatus = (episodeOfCareList: EpisodeOfCareWithStatus[]): string => {
  const status = isEmpty(episodeOfCareList) ? '' : episodeOfCareList[0].status;
  return status ? capitalize(getProgramStatusFromEpisodeOfCareStatus(status) || '') : '';
};

export interface PatientTag {
  system?: Maybe<string>;
  code?: Maybe<string>;
  display?: Maybe<string>;
}
/**
 *
 * @param patientTags - the tags on the patient to check for eligiblity
 * @returns if the patient is eligible
 */
export const isEligible = (patientTags: PatientTag[]): boolean => {
  return patientTags?.find((tag) => tag.system === System.Attribution)?.code === Attribution.Attributed;
};

export const isEnrolled = (patient: Patient): boolean => {
  return patient.meta?.tag?.find((tag) => tag.system === System.ProgramStatus)?.code === ProgramStatus.Enrolled;
};

export const isOnboarded = (patient: Patient): boolean => {
  return patient.meta?.tag?.find((tag) => tag.system === System.ProgramStatus)?.code === ProgramStatus.Onboarded;
};

// TODO: Doc and test
export const getPatientExtension = (extensions: FHIRExtension[], targetUrl: string): string | undefined => {
  const matchedItem = (extensions || []).find((item) => item?.url === targetUrl);
  return matchedItem ? matchedItem.valueCode : undefined;
};

// TODO: Doc and test
export const getPatientLanguagePreference = (communication: PatientCommunication[]): string[] => {
  return communication?.map((item) => {
    const codingSystem = item?.language?.coding?.[0]?.system;
    const codingDisplay = item?.language?.coding?.[0]?.display ?? '';

    if (codingSystem === System.PreferredLanguage) {
      return codingDisplay;
    }
    return '';
  });
};

export const getPatientFirstLanguagePreferenceCode = (communication?: PatientCommunication[]): string => {
  if (!communication) {
    return PreferredLanguage.En;
  }

  return (
    communication?.map((item) => {
      const codingSystem = item?.language?.coding?.[0]?.system;
      const codingCode = item?.language?.coding?.[0]?.code ?? 'en';

      if (codingSystem === System.PreferredLanguage) {
        return codingCode;
      }
      return '';
    })[0] || PreferredLanguage.En
  );
};

// TODO: Doc and test
export const getPatientRequiresInterpreter = (extension: FHIRExtension[]): boolean => {
  return extension?.some((ext) => ext.url === HL7System.InterpreterRequired.toString() && ext.valueBoolean === true);
};

/**
 *
 * @param caregiver - The caregiver to find linked patients through
 * @param primaryPatientId - The id of the patient to find other linked patients from
 * @returns A list of patients that are linked to the primary patient through caregivers excluding the primary patient
 */
// TODO: Test
export const relatedPersonsFromCaregiver = (caregiver: Maybe<Patient>, primaryPatientId: string): RelatedPerson[] => {
  if (!caregiver) {
    return [];
  }

  // Since we link patients to each other through caregivers we have to find
  // the patients that are linked to the caregivers, and exclude any that are the patient being viewed.
  return (map(map(caregiver.link, 'other'), 'resource') as RelatedPerson[]).filter(
    (rp) => rp?.patient?.resource?.id !== primaryPatientId,
  );
};

// TODO: Doc and test
export const caregiversFromPatient = (patient: Partial<Omit<GraphqlPatient, 'resourceType'>>) => {
  if ('RelatedPersonList' in patient) {
    return compact(compact(patient.RelatedPersonList).map((relatedPerson) => relatedPerson.PatientList![0]));
  }

  throw new Error('Patient does not have RelatedPersonList');
};

/**
 *
 * @param patient - the patient to find the athena id for
 * @returns the athena id for the patient
 */
export const athenaIdentifier = (patient: Patient | GraphqlPatient): Maybe<string> | undefined => {
  return patient.identifier?.find((id) => id.system === System.AthenaId)?.value;
};

/**
 *
 * @param careTeamList - the care team list to find the pediatric rn in
 * @returns the pediatric rn for the given patient
 *
 * Search through the participants of the patient's CareTeamList
 * to find the participants who have a role of 'pediatricrn'.
 */
// TODO: Test
export const pediatricRnFromPatient = (careTeamList: Maybe<CareTeam[]>): Maybe<Practitioner> => {
  if (!careTeamList || isEmpty(careTeamList)) {
    return null;
  }

  const participants = compact(flatten(careTeamList.map((ct) => ct?.participant)));

  const pediatricRns: CareTeamParticipant[] = participants.filter((participant) => {
    // Find the participant with a role of 'pediatricrn'
    return !isEmpty(
      participant.role?.find((role) => role.coding?.find((coding) => coding.code === CareTeamMemberRole.PediatricRN)),
    );
  });

  return pediatricRns[0]?.member?.resource as Practitioner;
};

// TODO: Doc and Test
export const acuityFromClinicalImpressions = (
  clinicalImpressionList: Maybe<ClinicalImpression[]>,
): string | undefined => {
  return clinicalImpressionList
    ?.filter((impression) => impression?.code?.coding?.some((coding) => coding?.code === 'acuity'))
    ?.map((impression) => impression?.summary)
    .join(', ');
};

interface PatientNameOptions {
  use?: HumanName['use'];
  givenOnly?: boolean;
  familyOnly?: boolean;
}

/**
 *
 * @param nameable - name of the nameable
 * @param options - options for the getting nameable name
 * @returns the formatted nameable name
 */
export const getName = (
  nameable?: Maybe<{
    name?: HumanName[] | GraphqlHumanName[] | null;
  }>,
  options?: PatientNameOptions,
): Maybe<string> => {
  if (!nameable?.name) {
    return null;
  }

  if (!options?.use && !options?.familyOnly && !options?.givenOnly) {
    return formatHumanName(nameable.name[0] as HumanName);
  }

  if (!options.use) {
    return formatHumanName(nameable.name[0] as HumanName);
  }

  const nameForUse = nameable.name.find((n) => n.use === options.use);
  if (!nameForUse) {
    if (options.givenOnly) {
      return nameable.name[0].given![0] || '';
    } else if (options.familyOnly) {
      return nameable.name[0].family || '';
    }

    return formatHumanName(nameable.name[0] as HumanName);
  } else {
    if (options.givenOnly) {
      return nameForUse.given![0] || '';
    } else if (options.familyOnly) {
      return nameForUse.family || '';
    }

    return formatHumanName(nameForUse as HumanName);
  }
};

export const isPrimaryCaregiver = (
  patient?: PatientWithExtension,
  caregiver?: PatientWithExtension,
): boolean | undefined => {
  if (!patient || !caregiver) {
    return undefined;
  }

  if (!caregiver.id) {
    return undefined;
  }

  const existingPrimaryCaregiverReference = patient.extension?.find((e) => e.url === System.PrimaryCaregiver.toString())
    ?.valueReference?.reference;

  const relatedPatientReference = `Patient/${caregiver.id}`;
  const primary =
    (existingPrimaryCaregiverReference && existingPrimaryCaregiverReference === relatedPatientReference) ?? undefined;

  return typeof primary === 'string' ? undefined : primary;
};

/**
 * reconcilePatientPrimaryContact mutates the provided patient and also returns it
 * the extension list will be modified to resolve the correct primary caregiver or remove if the request indicates such
 *
 * @param contactRequest - the PatientContactRequest object
 * @param patient - the Patient resource of the patient
 * @param caregiver - the Patient resource of the caregiver
 * @returns Patient
 */
export const reconcilePatientPrimaryContact = (
  contactRequest: PatientContactRequest,
  patient: Patient,
  caregiver: Patient,
): Patient => {
  if (contactRequest.contact.primary === undefined) {
    return patient;
  }

  if (contactRequest.contact.primary) {
    patient.extension = addExtensions(patient.extension, [
      {
        url: System.PrimaryCaregiver,
        valueReference: createReference(caregiver),
      },
    ]);
  } else {
    // if incoming contact id is equal to existing primary caregiver contact id, we must remove the primary caregiver extension because primary has been explicitly set to `false`
    // this is less relevant to enrollment but a primary may already exist from migration
    patient.extension = patient.extension?.filter((e) => {
      if (e.url !== System.PrimaryCaregiver.toString()) {
        return true;
      }

      const id = e.valueReference?.reference?.split('/')?.[1];
      return id !== contactRequest.contact.contactId;
    });
  }

  return patient;
};

export const preferredLanguage = (patient: Patient | undefined): string | undefined => {
  return patient?.communication?.find((comm) => comm.preferred)?.language?.coding?.[0].display;
};

type PatientGroup = {
  patient: Patient;
  linkedThrough: RelatedPerson[];
};

/**
 * Returns a unique list of patients that are linked to the primary patient through one or more contacts
 * @param relatedPersons - RelatedPerson[] list of related persons
 * @param patient - Patient The patient to find related patients for within the
 * @returns unique list of patients that are linked to the primary patient through one or more contacts
 */
export const makeGroupedLinkedPatientsList = (
  relatedPersons: RelatedPerson[],
  patient: Patient,
): {
  patient: Patient;
  linkedThrough: RelatedPerson[];
}[] => {
  const patientGroups: Record<string, PatientGroup> = {};

  relatedPersons.forEach((relatedPerson) => {
    relatedPerson?.PatientList?.at(0)?.link?.forEach((link) => {
      const otherRelatedPerson = link?.other?.resource as RelatedPerson;
      const linkedPatient = otherRelatedPerson?.patient.resource as Patient;
      if (!linkedPatient?.id || linkedPatient.id === patient.id) {
        return;
      }

      const linked: RelatedPerson = {
        ...otherRelatedPerson,
        patient: {
          resource: relatedPerson?.PatientList?.at(0),
        },
      };

      if (!patientGroups[linkedPatient.id]) {
        patientGroups[linkedPatient.id] = {
          patient: linkedPatient,
          linkedThrough: [linked],
        };
      } else {
        patientGroups[linkedPatient.id].linkedThrough.push(linked);
      }
    });
  });

  return Object.values(patientGroups);
};

/**
 * Determines if a given contact point is marked as the primary phone.
 * @param telecom - The contact point to check.
 * @returns true if the contact point is marked as the primary phone, false otherwise.
 */
export const isPrimaryPhone = (telecom: ContactPoint): boolean => {
  return (
    telecom.extension?.some((ext) => ext.url === System.PrimaryPhone.toString() && ext.valueBoolean === true) ?? false
  );
};

export function bundleEntryIsPatient(entry: BundleEntry): entry is BundleEntry<Patient> {
  return entry.resource?.resourceType === 'Patient';
}

/**
 * Updates the program status history for a patient's episode of care.
 * This function modifies the status history based on the new status provided.
 * It checks if the last status is the same as the new status to update the period or add a new status.
 * It also handles specific system URLs, particularly for outreach status, to ensure the correct handling of extensions.
 *
 * @param statusHistory - The current status history of the episode of care.
 * @param newStatus - The new status to be added to the history.
 * @param formattedDate - The date to be used for the status update.
 * @param systemUrl - The URL of the system for the extension.
 * @param valueCode - The value code for the extension.
 * @param outreachStatusReason - The reason for the outreach status.
 * @returns The updated status history.
 */
export const updateProgramStatusHistory = (
  statusHistory: EpisodeOfCareStatusHistory[],
  newStatus: EpisodeOfCareStatusHistory,
  formattedDate: string,
  systemUrl: string,
  valueCode: string,
  outreachStatusReason?: string,
): EpisodeOfCareStatusHistory[] => {
  if (statusHistory.length > 0) {
    const lastStatusHistory = statusHistory[statusHistory.length - 1];
    const lastExtension = lastStatusHistory?.extension?.find((ext) => ext.url === systemUrl);
    const lastValueCode = lastExtension?.valueCode;
    const lastOutreachStatusReason = lastStatusHistory.extension?.find(
      (ext) => ext.url === System.OutreachStatusReason.toString(),
    )?.valueCode;

    if (systemUrl !== System.OutreachStatus.toString()) {
      if (lastStatusHistory.status === newStatus.status) {
        lastStatusHistory.period.start = formattedDate;
        const previousStatusIndex = statusHistory.length - 2;
        if (previousStatusIndex >= 0) {
          statusHistory[previousStatusIndex].period.end = formattedDate;
        }
      } else {
        lastStatusHistory.period.end = formattedDate;
        statusHistory.push(newStatus);
      }
    }

    if (systemUrl === System.OutreachStatus.toString()) {
      if (
        lastStatusHistory.status === newStatus.status &&
        lastExtension?.valueCode === newStatus.extension?.[0]?.valueCode &&
        lastOutreachStatusReason === outreachStatusReason
      ) {
        lastStatusHistory.period.start = formattedDate;

        statusHistory[statusHistory.length - 2].period.end = formattedDate;
      } else {
        lastStatusHistory.period.end = formattedDate;

        statusHistory.push(newStatus);
      }
    } else if (valueCode && lastValueCode && lastValueCode !== valueCode) {
      if (lastExtension) {
        lastExtension.valueCode = valueCode;
      } else {
        lastStatusHistory.extension = lastStatusHistory.extension || [];
        lastStatusHistory.extension.push({
          url: systemUrl,
          valueCode: valueCode,
        });
      }
    }
  } else {
    statusHistory.push(newStatus);
  }

  return statusHistory;
};
