import React from 'react';
import { ActionIcon, Box, Group, Paper, Radio, RadioGroup, Select, Stack, Text, TextInput, Title } from '@mantine/core';
import { System } from 'const-utils';
import { useOutreach } from '../Context';
import { formatPhoneString } from 'imagine-dsl/utils/strings';
import { IconCopy } from '@tabler/icons-react';
import { PhoneInput } from '@/design-system/PhoneInput';
import { copyToClipboardHandler } from '@/components/shared/patient/clipboard';
import { outreachModalityOptions } from 'const-utils/codeSystems/ImaginePediatrics';
import { DateTimePicker } from '@mantine/dates';
import { endOfToday } from 'date-fns';

export const ContactCaregiver = (): JSX.Element => {
  const { contactCaregiverForm: form, phoneNumbers, patient } = useOutreach();
  const [newPhoneNumber, setNewPhoneNumber] = React.useState<string>('');

  const phoneNumberOptions = [...phoneNumbers].filter(Boolean).map((number) => {
    const telecom = patient?.telecom?.find((telecom) => telecom.value === number);
    const phoneStatusExtension = telecom?.extension?.find((ext) => ext.url === System.PhoneStatus.toString());
    const isPrimary =
      telecom?.extension?.find((ext) => ext.url === System.PrimaryPhone.toString())?.valueBoolean ?? false;
    const valueCode = phoneStatusExtension?.valueCode;

    return {
      value: number as string,
      label: formatPhoneString(number),
      status: isPrimary ? 'Primary' : valueCode ?? '',
    };
  });

  return (
    <form id="contact-caregiver-form">
      <Paper shadow="xs" p="md">
        <Title fw="normal" mb="xl" order={3} c="imagine-green" size="26px">
          Contact caregiver
        </Title>
        <Select
          mb="md"
          size="sm"
          data={outreachModalityOptions}
          required
          label="Modality"
          {...form.getInputProps('modality')}
        />
        <Stack>
          <Box
            style={{
              maxWidth: '289px',
            }}
          >
            <RadioGroup required label="Phone number" {...form.getInputProps('phoneNumber')} name="phoneNumber">
              {phoneNumberOptions.map((option) => {
                return (
                  <div style={{ marginBottom: 10 }}>
                    <Group>
                      <Radio key={option.value} value={option.value} label={option.label} />
                      <ActionIcon color="imagine-green" size="sm" onClick={() => copyToClipboardHandler(option?.value)}>
                        <IconCopy />
                      </ActionIcon>
                    </Group>
                    <Text ml={'xl'} size="xs" c="dimmed">
                      {option.status}
                    </Text>
                  </div>
                );
              })}
              <div style={{ marginBottom: 10 }}>
                <Group>
                  <Radio key={'newPhoneNumber'} value={newPhoneNumber} />
                  <PhoneInput
                    label="Add new phone number"
                    value={newPhoneNumber}
                    name="addNewPhoneNumber"
                    onChange={(value) => {
                      const newVal = value as unknown as string;
                      setNewPhoneNumber(newVal);
                      form.setFieldValue('phoneNumber', newVal);
                    }}
                  />
                </Group>
              </div>
            </RadioGroup>
          </Box>
          <Group grow>
            <DateTimePicker maxDate={endOfToday()} label="Date" required {...form.getInputProps('date')} />
          </Group>
          <Group grow>
            <TextInput name="contactFirstName" label="Contact first name" {...form.getInputProps('contactFirstName')} />
            <TextInput name="contactLastName" label="Contact last name" {...form.getInputProps('contactLastName')} />
          </Group>
        </Stack>
      </Paper>
    </form>
  );
};

export default ContactCaregiver;
