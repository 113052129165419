import React, { useEffect, useMemo, useState } from 'react';
import {
  Patient,
  useGetSurveysForPatientQuery,
  useGetReviewTasksByPatientIdQuery,
  useGetSurveyCommunicationForTasksQuery,
  RelatedPerson,
} from 'medplum-gql';
import { caregiversFromPatient, makeGroupedLinkedPatientsList } from 'imagine-dsl/utils/patient';
import { compact } from 'lodash';
import { SurveyTask } from 'imagine-dsl/models/tasks/surveyTask';
import { Collapse, Group, Loader, Text, ThemeIcon } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { EmptyResource } from './EmptyResource';
import { TasksDrawer } from '@/components/tasks/drawer';
import { SurveyReviewTask } from 'imagine-dsl/models/tasks/surveyReviewTask';
import { SurveyItem } from './surveyResource/SurveyItem';
import { SendSurveyButton } from './surveyResource/SendSurveyButton';
import { Patient as FHIRPatient, Task } from '@medplum/fhirtypes';
import { TaskType } from 'const-utils';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { useTaskCountContext } from '@/pages/Task/TaskCountProvider';

interface SurveysProps {
  patient: Patient;
  linkedPatients: RelatedPerson[];
}

export const Surveys = ({ patient, linkedPatients }: SurveysProps): JSX.Element => {
  const groupedLinkedPatients = makeGroupedLinkedPatientsList(linkedPatients, patient as FHIRPatient);
  const patientIds = [patient.id, ...groupedLinkedPatients.map((p) => p.patient.id)].filter(Boolean).join(',');

  const [resourcesOpened, setResourcesOpened] = useState(false);
  const { data, loading, refetch } = useGetSurveysForPatientQuery({
    variables: { subject: patientIds },
    skip: !patientIds,
    pollInterval: 60_000,
  });

  const { selectedSurveyId, setSelectedSurveyId } = useTaskCountContext();
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyTask | undefined>();

  const reviewTaskTags = [TaskType.ScreenerReview, TaskType.ReviewBHSurvey, TaskType.ReviewDisasterPreparednessSurvey];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { data: reviewTaskData } = useGetReviewTasksByPatientIdQuery({
    variables: { patientId: patient.id!, tags: reviewTaskTags.join(',') },
    skip: !patient.id,
    pollInterval: 60_000,
  });

  const {
    data: communicationData,
    startPolling: startPollingComms,
    stopPolling: stopPollingComms,
  } = useGetSurveyCommunicationForTasksQuery({
    variables: {
      taskIds:
        compact(data?.TaskList)
          .map((task) => `Task/${task.id}`)
          .join(',') || '',
    },
    skip: !patient.id || (data?.TaskList?.length ?? 0) === 0,
  });

  const refetchTasks = async (): Promise<void> => {
    await refetch();
    startPollingComms(10000);
  };

  useEffect(() => {
    if (
      data?.TaskList &&
      communicationData?.CommunicationList &&
      data?.TaskList?.length === communicationData?.CommunicationList?.length
    ) {
      stopPollingComms();
    }
  }, [data, communicationData, stopPollingComms]);

  const surveys = compact(data?.TaskList).map((task) => new SurveyTask(task));
  useEffect(() => {
    if (selectedSurveyId) {
      const chosenSurvey = surveys.find((survey) => survey.id === selectedSurveyId);
      if (chosenSurvey) {
        setSelectedSurvey(chosenSurvey);
        setIsDrawerOpen(true);
      }
    }
  }, [selectedSurveyId, surveys]);

  const caregivers = useMemo(() => caregiversFromPatient(patient), [patient]);
  const communicationForTask = (taskId: string) => {
    return communicationData?.CommunicationList?.find((communication) => {
      return communication?.basedOn?.find((relatedTask) => (relatedTask.resource as Task)?.id === taskId);
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const reviewTaskForSurvey = (surveyTaskId: string) => {
    const reviewTaskResult = reviewTaskData?.TaskList?.find(
      (task) => task?.basedOn?.find((basedOn) => (basedOn.resource as Task).id === surveyTaskId),
    );

    return reviewTaskResult ? new SurveyReviewTask(reviewTaskResult) : undefined;
  };

  const toggleDrawer = (survey: SurveyTask) => {
    setSelectedSurvey(survey);
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Group align="center" justify="space-between">
        <Group onClick={() => setResourcesOpened(!resourcesOpened)}>
          {resourcesOpened ? (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronDown size={15} />
            </ThemeIcon>
          ) : (
            <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
              <IconChevronRight size={15} />
            </ThemeIcon>
          )}
          <Text style={{ cursor: 'pointer' }} c="imagine-green">
            Surveys
          </Text>
        </Group>
        <SendSurveyButton
          caregivers={caregivers as FHIRPatient[]}
          patient={patient as FHIRPatient}
          refetchTask={refetchTasks}
          surveyTasks={surveys}
        />
        <Collapse in={resourcesOpened}>
          {!surveys.length ? (
            <EmptyResource />
          ) : (
            <>
              {surveys.map((survey) => (
                <React.Fragment key={survey.id}>
                  <SurveyItem
                    title={survey.focus?.name || 'Unknown survey'}
                    surveyTask={survey}
                    setSelectedSurvey={toggleDrawer}
                    communicationSentDateTime={communicationForTask(survey.id!)?.sent || ''}
                    communicationRead={!!communicationForTask(survey.id!)?.received}
                  />
                </React.Fragment>
              ))}
            </>
          )}
          {selectedSurvey && selectedSurvey.completed && (
            <TasksDrawer
              withViewPatientProfile
              task={
                reviewTaskForSurvey(selectedSurvey.id!) &&
                BaseTask.fromSurveyReviewTask(reviewTaskForSurvey(selectedSurvey.id!)!)
              }
              surveyTask={selectedSurvey}
              patientId={selectedSurvey.for?.id || ''}
              onClose={() => {
                setSelectedSurveyId(undefined);
                setIsDrawerOpen(false);
              }}
              opened={isDrawerOpen}
              refetchTasks={[refetch]}
            />
          )}
        </Collapse>
      </Group>
    </>
  );
};
