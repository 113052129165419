import { CareTeam, Extension } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { CareTeamMemberRole } from 'const-utils/codeSystems/ImaginePediatrics';
import { GetPatientQuery } from 'medplum-gql';
import { getExternalCareTeamMemberByRole } from 'imagine-dsl/utils/careTeam';
import {
  getName,
  getPatientExtension,
  getPatientLanguagePreference,
  getPatientRequiresInterpreter,
} from 'imagine-dsl/utils/patient';
import { yesOrNo } from 'imagine-dsl/utils/strings';
import React, { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { FormValidationRules } from '../helpers/EditFormValidation';
import { UseFormReturnType, useForm } from '@mantine/form';
import { PatientEditModalForm } from '../helpers/EditFormInterfaces';
import { getErrorMessage } from '@/errors';
import { notifications } from '@mantine/notifications';
import { PatientPrimaryInfoFields, editFormHandler, getFormFieldNames } from '../helpers/EditFormHandler';
import pcpDataRetention from '../patientEditableFields/Pcp/PcpDataRetention';
import addressDataRetention from '../patientEditableFields/address/AddressDataRetention';
import genderIdentityDataRetention from '../patientEditableFields/genderIdentity/GenderIdentityDataRetention';
import languagePreferenceDataRetention from '../patientEditableFields/languagePreference/LanguagePreferenceDataRetention';
import livingSituationDataRetention from '../patientEditableFields/livingSituation/LivingSituationDataRetention';
import preferredNameDataRetention from '../patientEditableFields/preferredName/PreferredNameDataRetention';
import { useMedplum } from '@medplum/react';

interface PatientProfileEditContext {
  loading: boolean;
  buttonDisabled: boolean;
  onSaveHandler: (e: React.FormEvent) => Promise<boolean>;
  form: UseFormReturnType<PatientEditModalForm, (values: PatientEditModalForm) => PatientEditModalForm>;
}

const PatientProfileEditContextValue = createContext<PatientProfileEditContext>({} as PatientProfileEditContext);

export const usePatientProfileEditContext = (): PatientProfileEditContext => {
  return useContext(PatientProfileEditContextValue);
};

interface PatientProfileEditProviderProps {
  patient: GetPatientQuery['Patient'];
  field: PatientPrimaryInfoFields;
  onDataChanged?: () => void;
  closeModal: () => void;
}

export function PatientProfileEditProvider({
  children,
  patient,
  field,
  onDataChanged,
  closeModal,
}: PropsWithChildren<PatientProfileEditProviderProps>): JSX.Element {
  const medplum = useMedplum();
  const [loading, setLoading] = useState(false);
  const patientAddress = patient?.address?.find(
    (address) =>
      address.extension?.find((ext) => ext.url === System.AddressType.toString() && ext.valueCode === 'home'),
  );

  const preferredName = getName(patient, { use: 'usual', givenOnly: true });
  const genderIdentity = getPatientExtension(patient?.extension as Extension[], System.GenderIdentity);
  const preferredPronoun = getPatientExtension(patient?.extension as Extension[], System.PreferredPronoun);
  const languagePreference = getPatientLanguagePreference(patient?.communication || []);
  const requiresInterpreter = yesOrNo(getPatientRequiresInterpreter(patient?.extension as Extension[]));
  const livingSituation = getPatientExtension(patient?.extension as Extension[], System.LivingArrangement);
  const pcp = getExternalCareTeamMemberByRole(
    (patient?.externalCareTeam || []) as CareTeam[],
    CareTeamMemberRole.PrimaryCareProvider,
  );

  const address = {
    line1: patientAddress?.line?.[0] ?? '',
    line2: patientAddress?.line?.[1] ?? '',
    city: patientAddress?.city ?? '',
    state: patientAddress?.state ?? '',
    zip: patientAddress?.postalCode ?? '',
  };

  const initialValues = {
    preferredName: preferredName ?? '',
    genderIdentity: genderIdentity ?? '',
    preferredPronoun: preferredPronoun ?? '',
    languagePreference: languagePreference?.[0] ?? '',
    requiresInterpreter: requiresInterpreter || '',
    livingSituation: livingSituation || '',
    pcp: pcp || '',
    address: address || {},
    phones: '',
  };

  const form = useForm<PatientEditModalForm>({
    initialValues,
    validateInputOnBlur: true,
    validate: FormValidationRules,
  });

  const buttonDisabled = useMemo(() => {
    if (loading) {
      return true;
    }

    const fieldNames = getFormFieldNames(field!);
    if (!fieldNames) {
      return true;
    }

    // For address field validation
    if (field === PatientPrimaryInfoFields.ADDRESS) {
      const addressFields = ['line1', 'line2', 'city', 'state', 'zip'];
      const hasDirtyField = form.isDirty('address');
      const hasErrors = addressFields.some((addressField) => {
        return !form.isValid(`address.${addressField}`);
      });
      return hasErrors || !hasDirtyField; // Enable button if at least one field is updated and valid
    }

    if (field === PatientPrimaryInfoFields.GENDER_IDENTITY) {
      const isFieldDirty = form.isDirty('genderIdentity') || form.isDirty('preferredPronoun');

      if (form.values.genderIdentity === 'does not wish to disclose') {
        const hasNoErrors = !form.errors.genderIdentity && !form.errors.preferredPronoun;
        return !(isFieldDirty && hasNoErrors);
      } else {
        const hasNoErrors = !form.errors.genderIdentity;
        return !(isFieldDirty && hasNoErrors && form.values.genderIdentity && form.values.preferredPronoun);
      }
    }

    // For other fields
    return !fieldNames.some((fieldName: string) => {
      const key = fieldName as keyof PatientEditModalForm;
      const isFieldDirty = form.isDirty(key);
      const hasNoErrors = !form.errors[key];
      const hasValue = form.values[key] !== '';
      return isFieldDirty && hasNoErrors && hasValue;
    });
  }, [field, form, loading]);

  if (!patient) {
    return <></>;
  }

  const onSaveHandler = async (e: React.FormEvent): Promise<boolean> => {
    e.preventDefault();
    const values = form.values;
    const dataRetentionHandlers = {
      [PatientPrimaryInfoFields.LIVING_SITUATION]: async () =>
        livingSituationDataRetention(values.livingSituation, patient.id!, medplum),
      [PatientPrimaryInfoFields.GENDER_IDENTITY]: async () =>
        genderIdentityDataRetention(values.genderIdentity, values.preferredPronoun, patient.id!, medplum),
      [PatientPrimaryInfoFields.PREFERRED_NAME]: async () =>
        preferredNameDataRetention(values.preferredName, patient.id!, medplum),
      [PatientPrimaryInfoFields.LANGUAGE_PREFERENCE]: async () =>
        languagePreferenceDataRetention(values.languagePreference, values.requiresInterpreter, patient.id!, medplum),
      [PatientPrimaryInfoFields.PCP]: async () => pcpDataRetention(values.pcp, patient, medplum),
      [PatientPrimaryInfoFields.ADDRESS]: async () => addressDataRetention(values.address, patient.id!, medplum),
    };
    let isValid = false;
    setLoading(true);
    try {
      isValid = await editFormHandler(form, field!, dataRetentionHandlers);
    } catch (err: unknown) {
      notifications.show({
        title: 'Error',
        message: getErrorMessage(err, `Something went wrong saving ${field}`),
        color: 'status-error',
      });
    }
    setLoading(false);

    if (!isValid) {
      return false;
    }

    if (onDataChanged) {
      onDataChanged();
    }

    closeModal();

    return true;
  };

  return (
    <PatientProfileEditContextValue.Provider value={{ buttonDisabled, loading, form, onSaveHandler }}>
      {children}
    </PatientProfileEditContextValue.Provider>
  );
}
