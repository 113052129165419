import { Practitioner, Task } from '@medplum/fhirtypes';
import {
  GetChatTimelineByPatientIdQuery,
  GetChatTimelineByPatientIdQueryResult,
  useGetChatTimelineByPatientIdQuery,
  GetLastChatTaskByPatientIdQuery,
  Communication,
  Task as GraphQLTask,
  useGetLastIncompleteChatTasksByPatientIdQuery,
  GetLastIncompleteChatTasksByPatientIdQuery,
} from 'medplum-gql';
import React, { useEffect } from 'react';
import { usePatientChatChannelSubscription, usePatientTaskChannelSubscription } from '../PusherProvider';
import { ApolloQueryResult } from '@apollo/client';
import { compact } from 'lodash';
import { FetchedPatient } from '@/utils/queryTypes';
import { useUserSession } from '../shared/UserSessionContext';

export interface ChatDrawerProviderContext {
  timelineLoading: boolean;
  timelineError: GetChatTimelineByPatientIdQueryResult['error'];
  refetchTimeline: () => Promise<ApolloQueryResult<GetChatTimelineByPatientIdQuery>>;
  messages: Communication[];
  incompleteTasksLoading: boolean;
  refetchTasks: () => Promise<ApolloQueryResult<GetLastIncompleteChatTasksByPatientIdQuery>>;
  onClose: () => void;
  completedTasks: GraphQLTask[];
  patient: FetchedPatient;
  profile: Practitioner;
  incompleteTask: Task | undefined;
  opened: boolean;
  loadMoreTimeline: () => Promise<void>;
  totalTimelineCount: number;
}

const ChatDrawerProviderContextValue = React.createContext<ChatDrawerProviderContext | undefined>(undefined);

interface ChatDrawerProviderProps {
  patient: FetchedPatient;
  close: () => void;
  currentTask?: Task;
  refetchFocusedTask?: () => Promise<ApolloQueryResult<GetLastChatTaskByPatientIdQuery>>;
  opened: boolean;
}

export const useChatDrawer = (): ChatDrawerProviderContext => {
  const context = React.useContext(ChatDrawerProviderContextValue);
  if (!context) {
    throw new Error('useChatDrawerProviderContext must be used within a ChatDrawerProvider');
  }
  return context;
};

export function ChatDrawerProvider({
  children,
  patient,
  close,
  currentTask,
  opened,
  refetchFocusedTask,
}: React.PropsWithChildren<ChatDrawerProviderProps>): JSX.Element {
  const { profile } = useUserSession();
  const [messages, setMessages] = React.useState<Communication[]>([]);
  const {
    loading: timelineLoading,
    data: timelineData,
    error: timelineError,
    refetch: refetchTimeline,
    fetchMore: fetchMoreTimeline,
  } = useGetChatTimelineByPatientIdQuery({
    skip: !patient || !opened,
    pollInterval: 60000,
    variables: { patientId: `Patient/${patient.id}`, offset: 0 },
  });

  useEffect(() => {
    let resetMessages = false;

    if (messages.some((message) => message.subject?.resource?.id !== patient.id)) {
      resetMessages = true;
    }
    const newTimelineDataMessages =
      compact(timelineData?.CommunicationConnection?.edges?.map((edge) => edge?.resource) as Communication[]) || [];
    const messagesDif = newTimelineDataMessages.filter(
      (newMessage) => !messages.find((message) => newMessage.id === message.id),
    );

    if (resetMessages) {
      setMessages([...messagesDif]);
    } else {
      setMessages([...messagesDif, ...messages]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineData, patient.id]);

  const completedTasks =
    compact(timelineData?.TaskConnection?.edges?.map((edge) => edge?.resource) as GraphQLTask[]) || [];

  const {
    loading: incompleteTasksLoading,
    data: incompleteTasksData,
    refetch: refetchTasks,
  } = useGetLastIncompleteChatTasksByPatientIdQuery({
    variables: { patientId: `Patient/${patient?.id}` },
    skip: !patient || !!currentTask,
  });

  const chatPushes = usePatientChatChannelSubscription(
    { patientId: patient.id, eventName: 'new-chat', skip: !opened },
    async () => {
      await Promise.all([refetchTasks(), refetchTimeline()]);
    },
  );

  const taskResolvedUpdates = usePatientTaskChannelSubscription(
    {
      patientId: patient.id,
      eventName: 'task-status-resolved',
      skip: !opened,
    },
    async () => {
      await Promise.all([refetchTasks(), refetchTimeline(), refetchFocusedTask?.()]);
    },
  );

  const taskUnresolvedUpdates = usePatientTaskChannelSubscription(
    {
      patientId: patient.id,
      eventName: 'task-status-unresolved',
      skip: !opened,
    },
    async () => {
      await Promise.all([refetchTasks(), refetchTimeline(), refetchFocusedTask?.()]);
    },
  );

  const unsubscribe = (): void => {
    chatPushes?.unsubscribe();
    taskResolvedUpdates?.unsubscribe();
    taskUnresolvedUpdates?.unsubscribe();
  };

  const onClose = (): void => {
    unsubscribe();
    close();
  };

  const incompleteTask = currentTask || (incompleteTasksData?.TaskList?.at(0) as Task | undefined);

  const loadMoreTimeline = async (): Promise<void> => {
    const result = await fetchMoreTimeline({ variables: { offset: messages.length } });
    setMessages([
      ...(result.data?.CommunicationConnection?.edges?.map((edge) => edge?.resource) as Communication[]),
      ...messages,
    ]);
  };

  const value = {
    timelineLoading,
    timelineError,
    refetchTimeline,
    messages: compact(messages),
    incompleteTasksLoading,
    refetchTasks,
    onClose,
    completedTasks,
    patient,
    profile,
    incompleteTask,
    opened,
    loadMoreTimeline,
    totalTimelineCount:
      (timelineData?.CommunicationConnection?.count || 0) + (timelineData?.TaskConnection?.count || 0),
  };

  return <ChatDrawerProviderContextValue.Provider value={value}>{children}</ChatDrawerProviderContextValue.Provider>;
}
