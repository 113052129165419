import React, { useMemo, useState } from 'react';
import { Badge, Box, Collapse, Text, Space, ThemeIcon, Group } from '@mantine/core';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { PatientContacts } from './PatientContacts';
import { CodeableConcept, Patient, RelatedPerson } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { modals } from '@mantine/modals';
import { capitalize } from 'lodash';
import { getName, isPrimaryCaregiver } from 'imagine-dsl/utils/patient';
import PatientCredentialsModal from '../modals/PatientCredentialsModal';
import PatientContactModal from '../modals/PatientContactModal';
import PatientContactActions from './PatientContactActions';
import classes from '../shared.module.css';
import { useApiClient } from '@/hooks/useApiClient';
import { notifications } from '@mantine/notifications';
import { getRelatedPersonRelationship } from 'imagine-dsl/utils/relatedPerson';
import { logError } from '@/errors';
import { SendAppDownloadLinkModal } from '../modals/SendAppDownloadLinkModal';
import CaregiverReactivationModal from '../modals/CaregiverReactivationModal';
import { ConsentUploadButton } from '../patientDocuments/ConsentUploadButton';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useMedplum } from '@medplum/react';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}
interface ExtendedPatient extends Patient {
  RelatedPersonList?: ExtendedRelatedPerson[];
}
interface ContactsListProps {
  patient: ExtendedPatient;
  openedContacts: OpenedContactsType;
  toggleDetails: (contactId: string | number) => void;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}
interface OpenedContactsType {
  [key: string]: boolean;
  [key: number]: boolean;
}

const modalTypes = {
  EDIT_CONTACT: 'editContact',
  ADD_CONTACT: 'addContact',
  EDIT_CREDENTIALS: 'editCredentials',
  SEND_APP_DOWNLOAD_LINK: 'sendAppDownloadLink',
  ACTIVATE_ACCOUNT: 'activateAccount',
} as const;
type ModalType = (typeof modalTypes)[keyof typeof modalTypes];

const PatientContactsList = ({ patient, openedContacts, toggleDetails, refetch }: ContactsListProps): JSX.Element => {
  const [selectedPerson, setSelectedPerson] = useState<ExtendedRelatedPerson>();
  const [whichModal, setWhichModal] = useState<ModalType>();
  const contactsList = useMemo(() => patient?.RelatedPersonList || [], [patient]);

  const apiClient = useApiClient();
  const medplum = useMedplum();
  const flags = useFeatureFlags();

  const onConfirmDeleteHandler = async (person: ExtendedRelatedPerson): Promise<void> => {
    const relatedPersonId = person?.id ?? '';
    const caregiver = person?.PatientList?.[0];

    if (caregiver) {
      await apiClient
        .fetch(`/api/patients/links`, {
          method: 'DELETE',
          body: JSON.stringify({
            caregiverId: caregiver.id,
            relatedPersonId: relatedPersonId,
          }),
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Unable to unlink caregiver');
        })
        .catch((error) => {
          logError(error);
          notifications.show({
            title: 'Unable to unlink caregiver',
            message: 'Please try again later',
            color: 'status-error',
          });
        });
      await refetch();
    }
  };

  const onDeleteHandler = (person: ExtendedRelatedPerson): void => {
    const openModal = (): void =>
      modals.openConfirmModal({
        title: (
          <Text fz="sm" fw={700} c="imagine-green">
            Remove Contact
          </Text>
        ),
        children: (
          <Text fz="xs">
            Are you sure you want to remove the contact
            <span style={{ fontWeight: 'bold' }}>
              {' ' + getName(person?.PatientList?.[0], { use: 'official' }) + ' '}
            </span>
            from the patient
            <span style={{ fontWeight: 'bold' }}>{' ' + getName(patient, { use: 'official' })}?</span>
          </Text>
        ),
        centered: true,
        radius: 'lg',
        labels: { confirm: 'Yes, remove Contact', cancel: 'Cancel' },
        onConfirm: () => onConfirmDeleteHandler(person),
      });

    openModal();
  };

  const changeModal = (whichModal: ModalType, person?: ExtendedRelatedPerson): void => {
    setSelectedPerson(person);
    setWhichModal(whichModal);
  };

  let PatientContactModalComponent = null;
  if (whichModal === modalTypes.EDIT_CONTACT) {
    PatientContactModalComponent = (
      <PatientContactModal
        key={selectedPerson?.id}
        selectedPerson={selectedPerson}
        patient={patient}
        refetch={refetch}
        setSelectedPerson={setSelectedPerson}
        isModalOpen={!!selectedPerson}
        closeModal={() => setSelectedPerson(undefined)}
        label="Edit contact"
        openAddContactModal={() => {
          changeModal(modalTypes.ADD_CONTACT);
        }}
      />
    );
  } else if (whichModal === modalTypes.ADD_CONTACT) {
    PatientContactModalComponent = (
      <PatientContactModal
        key={selectedPerson?.id}
        patient={patient}
        refetch={refetch}
        isModalOpen={whichModal === modalTypes.ADD_CONTACT}
        closeModal={() => {
          setWhichModal(undefined);
        }}
        label="Add contact"
      />
    );
  }

  return (
    <>
      {PatientContactModalComponent}
      {selectedPerson && whichModal === modalTypes.EDIT_CREDENTIALS && (
        <PatientCredentialsModal selectedPerson={selectedPerson} setSelectedPerson={setSelectedPerson} />
      )}
      {selectedPerson && whichModal === modalTypes.SEND_APP_DOWNLOAD_LINK && (
        <SendAppDownloadLinkModal
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
          refetch={refetch}
        />
      )}
      {selectedPerson && whichModal === modalTypes.ACTIVATE_ACCOUNT && (
        <CaregiverReactivationModal
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
          refetch={refetch}
        />
      )}

      {contactsList
        .filter((p) => !!p?.PatientList?.[0])
        .map((person: ExtendedRelatedPerson, index) => {
          const relatedPatient = person?.PatientList?.[0] as Patient;
          const isActive = relatedPatient?.active;

          return (
            <Box data-cy={`contact-list-${index}`} key={person?.id} className={classes.lineSeparatedListItem} py="sm">
              {isPrimaryCaregiver(patient, relatedPatient) && (
                <Badge color="blue" variant="filled" size="xs">
                  Primary
                </Badge>
              )}
              {!isActive && (
                <Badge color="red" variant="outline" size="xs" ml={4}>
                  DEACTIVATED
                </Badge>
              )}
              <Space h="sm" />
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Text fw={700} size="md">
                    {relatedPatient?.name
                      ? `${relatedPatient?.name[0]?.given?.[0]} ${relatedPatient?.name[0]?.family}`
                      : null}
                  </Text>
                  <Group justify="space-between">
                    <Text c="dimmed" fz="xs">
                      {(() => {
                        const contactType = person?.relationship?.find(
                          (relation: CodeableConcept) => relation?.coding?.[0]?.system === System.ContactType,
                        )?.coding?.[0]?.display;

                        const roleCode = getRelatedPersonRelationship(person)?.display;

                        return `${contactType ? contactType + ', ' : ''}${capitalize(roleCode) || ''}`;
                      })()}
                    </Text>
                    {isActive && (flags.ConsentUpload || medplum.isProjectAdmin()) && (
                      <ConsentUploadButton patient={relatedPatient as Patient} refetch={refetch} isCaregiver />
                    )}
                  </Group>
                </Box>

                <Box style={{ display: 'flex', alignSelf: 'flex-start' }}>
                  <PatientContactActions
                    active={isActive}
                    onEdit={() => changeModal('editContact', person)}
                    onDelete={() => onDeleteHandler(person)}
                    onRegenerateCredentials={() => changeModal('editCredentials', person)}
                    onSendAppDownloadLink={() => changeModal('sendAppDownloadLink', person)}
                    onActivate={() => changeModal('activateAccount', person)}
                  />
                </Box>
              </Box>
              <Box
                style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                onClick={() => toggleDetails(person?.id ?? '')}
              >
                {openedContacts[person?.id ?? ''] ? (
                  <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
                    <IconChevronDown size={15} />
                  </ThemeIcon>
                ) : (
                  <ThemeIcon color="imagine-green" variant="transparent" mr={5}>
                    <IconChevronRight size={15} />
                  </ThemeIcon>
                )}
                <Text
                  c="imagine-green"
                  style={{ display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}
                  fz="sm"
                >
                  View details
                </Text>
              </Box>
              <Collapse
                in={openedContacts[person?.id ?? '']}
                transitionDuration={300}
                transitionTimingFunction="linear"
              >
                <PatientContacts
                  patient={patient as Patient}
                  relatedPerson={person as RelatedPerson}
                  withDetails={true}
                />
              </Collapse>
            </Box>
          );
        })}
    </>
  );
};

export default PatientContactsList;
