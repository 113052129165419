import { Box, Button, Group } from '@mantine/core';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import React from 'react';
import { Link } from 'react-router-dom';
import athenaLogoSrc from '@assets/logo/athena.svg';
import { Patient } from '@medplum/fhirtypes';
import { athenaIdentifier } from 'imagine-dsl/utils/patient';
import { Notes } from './notes';

type linkOptions =
  | 'withViewPatientProfile'
  | 'withViewAthenaProfile'
  | 'withPatientNotesCondensed'
  | 'hidePatientNotes';

interface HeaderLinksProps {
  linkOptions: {
    [key in linkOptions]?: boolean;
  };
  patient: Patient;
  athenaId?: string;
}

export function HeaderLinks({ linkOptions, patient }: HeaderLinksProps): JSX.Element {
  const { withViewPatientProfile, withViewAthenaProfile, withPatientNotesCondensed, hidePatientNotes } = linkOptions;

  const athenaId = athenaIdentifier(patient);
  const displayAthenaLink = athenaId && withViewAthenaProfile;

  const displayPatientNotes = !hidePatientNotes && withPatientNotesCondensed;

  const ViewPatientProfileLink = (): JSX.Element => {
    return (
      <Link to={`/Patient/${patient.id}`}>
        <Button rightSection={<IconChevronRight />} variant="transparent" p={0} h="md">
          View patient profile
        </Button>
      </Link>
    );
  };

  const ViewAthenaProfileLink = (): JSX.Element => {
    return (
      <Link
        to={`https://athenanet.athenahealth.com/1/1/login/startoidc.esp?IDP=0oaoxt3sbz6A1synY297&DEEPLINK=1&TARGETURL=/27760/1/client/clientsummary.esp?ID=${athenaId}`}
        target="_blank"
      >
        <Button
          leftSection={<img src={athenaLogoSrc} height={16} />}
          rightSection={<IconExternalLink size={16} />}
          variant="transparent"
          p={0}
          h="lg"
        >
          View Athena profile
        </Button>
      </Link>
    );
  };

  const ViewPatientNotesLink = (): JSX.Element => {
    return <Notes patientId={patient.id!} />;
  };

  const enabledLinkComponents = (): JSX.Element[] => {
    const components = [];
    if (withViewPatientProfile) {
      components.push(<ViewPatientProfileLink />);
    }
    if (displayAthenaLink) {
      components.push(<ViewAthenaProfileLink />);
    }
    if (displayPatientNotes) {
      components.push(<ViewPatientNotesLink />);
    }

    return components;
  };

  return (
    <>
      <Group gap={0}>
        {enabledLinkComponents().map((Component, index) => (
          <React.Fragment key={index}>
            {index === 0 && Component}
            {index !== 0 && (
              <>
                <Box bg="brand-gray.4" ml={2} mr={4} h="md" w={2} style={{ borderRadius: '99px' }} />
                {Component}
              </>
            )}
          </React.Fragment>
        ))}
      </Group>
    </>
  );
}
