export enum FeatureFlag {
  TranslateMessage = 'translate-message',
  MergeTaskViews = 'merge-task-views',
  TestFlag = 'test_flag',
  ChatGQLQuery = 'chat-gql-query',
  PathwayReviewOwners = 'pathway-review-owners',
  ConsentUpload = 'consent_upload',
  BulkTaskingAssignOthers = 'bulk_tasking_assign_others',
  EngagementPodReassignment = 'engagement_pod_reassignment',
  DisasterPreparednessSurvey = 'Disaster_Preparedness_Survey',
  TaskDueDateFilter = 'tasks_due_date_filter',
  BulkUpdateEngagementPod = 'bulk_update_engagement_pod',
  QuestionnaireConsents = 'questionnaire_consents',
  PatientProfileOpenTasks = 'patient_profile_open_tasks',
  DyteVideoProvider = 'dyte_video_provider',
  OutreachStatusPebble = 'outreach_status_pebble',
}
