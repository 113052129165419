import React from 'react';
import { ActionIcon, Anchor, Container, Drawer, Group, Text } from '@mantine/core';
import { IconArrowsDiagonalMinimize2 } from '@tabler/icons-react';
import { ResponseCategoryBadge } from './ResponseCategoryBadge';
import { ReadOnlyQuestionnaireRepeatedGroup } from '../../../questionnaire/QuestionnaireFormGroup';
import { QuestionnaireItemType } from '@medplum/react';
import { ReadOnlyQuestionnaireRepeatableItem } from '../../../questionnaire/QuestionnaireRepeatableItem';
import { useQuestionnaireResponseContext } from './QuestionnaireResponseProvider';
import { useMediaQuery } from '@mantine/hooks';
import { Body } from './Body';
import { getPreviousItemsResponseLength, getResponseItemByLinkId } from '@/components/questionnaire/utils';

interface TaskDrawerProps {
  onClose: () => void;
  setDrawerExpanded: (expanded: boolean) => void;
  drawerExpanded: boolean;
  patientId: string;
}

export function ExpandedTaskDrawerContent(props: TaskDrawerProps): JSX.Element {
  const mediaMatch = useMediaQuery('(max-width: 1408px)');
  const { setDrawerExpanded, drawerExpanded, patientId } = props;
  const {
    items,
    patientName,
    translatedItemMapping,
    questionnaireResponse,
    responseCategory,
    questionnaireResponseLoading,
    questionnaire,
  } = useQuestionnaireResponseContext();

  const form = items.map((item, index) => {
    const itemResponse = getResponseItemByLinkId(questionnaireResponse, item.linkId);
    const previousItemsResponseLength = getPreviousItemsResponseLength(questionnaireResponse, items, index);

    const repeatedItem =
      item.type === QuestionnaireItemType.group ? (
        <>
          {!mediaMatch && (
            <Container style={{ borderRadius: '12px' }} pt={8} pb={22} my={8} w="630px" bg="white">
              <ReadOnlyQuestionnaireRepeatedGroup
                translatedItemMapping={translatedItemMapping}
                key={item.linkId}
                item={item}
                response={itemResponse}
                withDivider={false}
                previousItemTotal={previousItemsResponseLength}
                defaultOpen
              />
            </Container>
          )}
          {mediaMatch && (
            <Container mx={0} style={{ borderRadius: '12px' }} pt={8} pb={22} my={8} w="100%" bg="white">
              <ReadOnlyQuestionnaireRepeatedGroup
                translatedItemMapping={translatedItemMapping}
                key={item.linkId}
                item={item}
                response={itemResponse}
                withDivider={false}
                previousItemTotal={previousItemsResponseLength}
                defaultOpen
              />
            </Container>
          )}
        </>
      ) : (
        <ReadOnlyQuestionnaireRepeatableItem
          translatedItemMapping={translatedItemMapping}
          key={item.linkId}
          item={item}
          response={itemResponse?.[0]}
        />
      );

    return repeatedItem;
  });

  return (
    <Drawer.Content bg="brand-gray.1">
      <Drawer.Header h={3} bd="1px solid brand-gray.4">
        <Group w="100%">
          <Text c="dimmed">{questionnaire?.name ?? 'Survey'}</Text>
          <ResponseCategoryBadge size="sm" responseValue={responseCategory} />
          <Anchor underline="hover" href={`/Patient/${patientId}`}>
            {patientName}
          </Anchor>
          <Group ml="auto">
            <ActionIcon onClick={() => setDrawerExpanded(!drawerExpanded)} size="xl" variant="transparent">
              <IconArrowsDiagonalMinimize2 />
            </ActionIcon>
            <Drawer.CloseButton />
          </Group>
        </Group>
      </Drawer.Header>
      <Drawer.Body display="flex" p={0} h="calc(100% - 60px)" style={{ flexDirection: 'column' }}>
        <Body loading={questionnaireResponseLoading} questionnaireResponse={questionnaireResponse} expanded>
          {!mediaMatch && (
            <Group wrap="nowrap" mx={8} gap={8} align="flex-start" mb={6}>
              {form}
            </Group>
          )}

          {mediaMatch && <Container mb={6}>{form}</Container>}
        </Body>
      </Drawer.Body>
    </Drawer.Content>
  );
}
